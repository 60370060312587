<template>
  <v-row>
    <v-col cols="10">
      <v-file-input
        v-model="fileInput"
        accept=".har"
        label="Click here to select a file"
        outlined
        dense
      >
      </v-file-input>
    </v-col>
    <v-col cols="2">
      <v-btn
        :loading="submitLoading"
        :disabled="fileInput == null || fileInput.length == 0"
        width="110"
        style="height: 40px"
        class="white--text notcapital"
        color="black"
        @click.stop="submit"
      >
        <v-icon left> mdi-check-circle</v-icon>
        Upload
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    submitLoading: false,
    fileInput: [],
    errors: [],
  }),

  methods: {
    async submit() {
      const formData = new FormData();
      formData.append("file", this.fileInput);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      axios
        .post("/post-har", formData, config)
        .then((res) => {
          this.$router.push(`/results?id=${res.data.runId}`);
        })
        .catch((e) => alert(e.response.data));
    },
  },
};
</script>
