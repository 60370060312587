import Vue from 'vue'
import Router from 'vue-router'

import HarUploader from './components/HarUploader';
import ResultsDisplayer from './components/ResultsDisplayer';

Vue.use(Router);

const routes = [
  { path: '/', component: HarUploader },
  { path: '/results', component: ResultsDisplayer }
]

export default new Router({
  mode: 'history',
  routes 
});
