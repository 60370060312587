import Vue from 'vue'
import apiEndpoint from './apiEndpoint';
import App from './App.vue'
import axios from 'axios';
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

axios.defaults.baseURL =  apiEndpoint;
axios.defaults.headers.post['Content-Type'] = 'application/json'

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
