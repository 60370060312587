<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h4>{{ results.page.url }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-2">
        <v-card-title> Total </v-card-title>

        <v-data-table :headers="headers" :items="total" hide-default-footer />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-2">
        <v-card-title> Details </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          dense
          hide-default-footer
          disable-pagination
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";

const shortenBytes = (n) => {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? "KMGT"[k - 1] : "") + "b";
  const count = n / Math.pow(1000, k);
  var roundedString = count.toFixed(2);
  var rounded = Number(roundedString);
  return rounded + " " + rank;
};

export default {
  data: () => ({
    headers: [
      { text: "SSP", value: "ssp" },
      { text: "# Requests", value: "requests" },
      { text: "Total Weight", value: "totalWeight" },
      { text: "Average Weight", value: "averageWeight" },
    ],
    results: {
      page: {
        url: "",
      },
      requests: [],
    },
  }),
  computed: {
    items() {
      console.log(this.results);
      return this.results.requests.map((line) => ({
        ssp: line.ssp,
        requests: line.count,
        totalWeight: shortenBytes(line.weight),
        averageWeight: shortenBytes(line.weight / line.count),
      }));
    },
    total() {
      const output = { ssp: "Total", requests: 0, totalWeight: 0 };
      this.results.requests.forEach((line) => {
        output.requests += line.count;
        output.totalWeight += line.weight;
      });
      output.averageWeight = shortenBytes(output.totalWeight / output.requests);
      output.totalWeight = shortenBytes(output.totalWeight);
      return [output];
    },
  },
  async created() {
    const uuid = this.$route.query.id;
    this.results = await this.getData(uuid);
  },
  methods: {
    async getData(uuid) {
      try {
        const res = await axios.get(`/get?id=${uuid}`);
        return res.data;
      } catch (e) {
        alert(e.response.data);
      }
    },
  },
};
</script>
